<button #toggle class="icon-button secondary" aria-label="Report view settings">
    <i class="material-icons color-c2a">settings</i>
</button>

<dropdown-menu #dropdownMenu [trigger]="toggle" [closeOnClick]="false">
    <ng-template>
        <section class="view-settings flex-column gap-2">
            <label class="uni-label label-above">
                <span>Antall desimaler</span>
                <input
                    type="number"
                    class="no-spinners"
                    [(ngModel)]="reportService.settings.decimalPlaces"
                    (change)="viewSettingChange.emit()"
                />
            </label>

            <label class="uni-label label-above">
                <span>Språk</span>
                <select [(ngModel)]="reportService.settings.lang" (change)="viewSettingChange.emit()">
                    <option value="NO">Norsk</option>
                    <option value="EN">Engelsk</option>
                </select>
            </label>

            <rig-checkbox *ngIf="flipSignInput" [(ngModel)]="flipSignInput.value" (change)="onFlipSignChange()">
                {{ flipSignInput.label }}
            </rig-checkbox>

            <rig-checkbox
                *ngIf="hasGrouping"
                [(ngModel)]="reportService.settings.showGroupContent"
                (change)="viewSettingChange.emit()"
            >
                {{ showGroupContentCheckboxLabel }}
            </rig-checkbox>

            <rig-checkbox
                *ngIf="drilldownService.hasPopupDrilldown"
                [ngModel]="drilldownService.popupDrilldownEnabled"
                (ngModelChange)="drilldownService.setPopupDrilldownEnabled($event)"
            >
                Se detaljer når musepeker holdes over en rad
            </rig-checkbox>

            <button (click)="dropdownMenu?.hide()" class="secondary close-button mt-2">Lukk</button>
        </section>
    </ng-template>
</dropdown-menu>
