<section class="dropdown-menu report-drilldown-popup custom-scrollbar" *ngIf="dataLoaded">
    <h1 class="flex align-center gap-3">
        Åpne poster

        <span *ngIf="params?.number" class="filter-tag border rounded"> Konto: {{ params.number }} </span>
    </h1>

    <table>
        <tr>
            <th>Bilagsnr.</th>
            <th>Fakturanr.</th>
            <th class="date">Dato</th>
            <th class="date">Forfallsdato</th>
            <th class="number">Restbeløp</th>
            <th class="description">Beskrivelse</th>
            <th class="attachment"></th>
        </tr>

        <tr *ngFor="let row of data">
            <td>
                <a
                    routerLink="/accounting/transquery"
                    [queryParams]="{
                        JournalEntryNumber: row.JournalEntryNumberNumeric,
                        AccountYear: row.AccountYear,
                        ShowCrediteLined: false,
                    }"
                >
                    {{ row.JournalEntryNumber }}
                </a>
            </td>
            <td>{{ row.InvoiceNumber }}</td>
            <td class="date">{{ row.FinancialDate | date: 'dd.MM.yyyy' }}</td>
            <td class="date">{{ row.DueDate | date: 'dd.MM.yyyy' }}</td>
            <td class="number">{{ row.RestAmountCurrency | uninumberformat: 'money' }}</td>
            <td class="description" [matTooltip]="row.Description">{{ row.Description }}</td>
            <td class="attachment">
                <i *ngIf="row.Attachments > 0" (click)="showAttachments(row)" class="material-icons" role="button">
                    attachment
                </i>
            </td>
        </tr>
    </table>

    <section *ngIf="drilldownRoute" class="mt-3">
        {{ data?.length === 50 ? 'Visningen er begrenset til siste 50 elementer, gå til ' : 'Gå til ' }}

        <a [routerLink]="[drilldownRoute.url]" [queryParams]="drilldownRoute.queryParams"> detaljert visning </a>

        for å se mer.
    </section>
</section>
