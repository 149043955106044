import { filter, map } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit, Type } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { TabService, UniModules } from '../layout/navbar/tabstrip/tabService';
import { ReportDefinition, UniQueryDefinition } from '../../unientities';
import { UniModalService, ConfirmActions, IUniModal, UniPreviewModal } from '@uni-framework/uni-modal';
import { UniReportParamsModal } from './modals/parameter/reportParamModal';
import { SalaryWithholdingAndAgaModal } from './modals/salary-withholding-and-aga/salary-withholding-and-aga';
import { SalaryPaymentListModal } from './modals/salary-payment-list/salary-payment-list';
import { UntypedFormControl } from '@angular/forms';
import { AbsenceReportModal } from './modals/absence-report/absence-report';
import { AuthService } from '@app/authService';
import { FeaturePermissionService } from '@app/featurePermissionService';
import { ErrorService } from '@app/services/common/errorService';
import { PageStateService } from '@app/services/common/pageStateService';
import { ReportDefinitionService } from '@app/services/reports/reportDefinitionService';

interface IMainGroup {
    name: string;
    label: string;
    groups: Array<ISubGroup>;
    allGroups?: Array<ISubGroup>;
    featurePermission?: string;
    count?: number;
}

interface ISubGroup {
    name: string;
    label: string;
    reports: Array<IReport>;
    allreports?: Array<IReport>;
    keywords?: Array<string>;
    featurePermission?: string;
    routePermission?: string;
}

interface IReport {
    Name: string;
    Category: string;
    Filtered: boolean;
    Visible: boolean;
    IsQuery: boolean;
}

@Component({
    selector: 'uni-reports',
    templateUrl: './reports.html',
    styleUrls: ['./reports.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniReports implements OnInit {
    searchControl: UntypedFormControl;
    public activeTabIndex: number = 0;
    public mainGroups: Array<IMainGroup> = [
        {
            name: 'Sales',
            label: 'Salg',
            groups: [
                {
                    name: 'Product',
                    label: 'Produkt',
                    featurePermission: 'ui.sales.products',
                    reports: [],
                    keywords: ['Sales.Product'],
                },
                {
                    name: 'Quote',
                    label: 'Tilbud',
                    featurePermission: 'ui.sales.quotes',
                    reports: [],
                    keywords: ['Sales.Quote'],
                },
                {
                    name: 'Order',
                    label: 'Ordre',
                    featurePermission: 'ui.sales.orders',
                    reports: [],
                    keywords: ['Sales.Order'],
                },
                { name: 'Invoice', label: 'Faktura', reports: [], keywords: ['Sales.Invoice'] },
            ],
        },
        {
            name: 'Accounting',
            label: 'Regnskap',
            groups: [
                {
                    name: 'AccountStatement',
                    label: 'Kontoutskrifter',
                    reports: [],
                    keywords: ['Accounting.AccountStatement'],
                },
                { name: 'Balance', label: 'Saldolister', reports: [], keywords: ['Accounting.Balance'] },
                { name: 'Result', label: 'Resultat', reports: [], keywords: ['Accounting.Result'] },
                { name: 'Tax', label: 'Merverdiavgift', reports: [], keywords: ['Accounting.Tax'] },
            ],
        },
        {
            name: 'AnnualSettlement',
            label: 'Årsavslutning',
            groups: [
                {
                    name: 'AnnualAccount',
                    label: 'Årsregnskap',
                    featurePermission: 'ui_annual-settlement',
                    reports: [],
                    keywords: ['AnnualSettlement.AnnualAccount'],
                },
                {
                    name: 'Other',
                    label: 'Andre rapporter',
                    featurePermission: 'ui_annual-settlement',
                    reports: [],
                    keywords: ['AnnualSettlement.Other'],
                },
            ],
        },
        {
            name: 'Payroll',
            label: 'Lønn',
            groups: [
                { name: 'Payroll', label: 'Lønn', reports: [], keywords: ['Salary', 'Payroll'] },
                {
                    name: 'Absence',
                    label: 'Fravær',
                    routePermission: '/salary/absence',
                    reports: [],
                    keywords: ['Salary.Absence'],
                },
            ],
        },
        {
            name: 'Timetracking',
            label: 'Timer',
            featurePermission: 'ui.timetracking',
            groups: [{ name: 'Timeracking', label: 'Timeregistrering', reports: [], keywords: ['Timer'] }],
        },
        {
            name: 'Custom',
            label: 'Egendefinert',
            featurePermission: 'ui.reports.custom',
            groups: [
                { name: 'Custom', label: 'Ukategorisert', reports: [], keywords: [] },
                { name: 'Accounting', label: 'Regnskap', reports: [], keywords: [] },
            ],
        },
    ];
    public filteredMainGroups: Array<IMainGroup> = [];
    controlSubscription: any;

    constructor(
        private tabService: TabService,
        private reportDefinitionService: ReportDefinitionService,
        private router: Router,
        private route: ActivatedRoute,
        private errorService: ErrorService,
        private uniModalService: UniModalService,
        private pageStateService: PageStateService,
        private authService: AuthService,
        private featurePermissionService: FeaturePermissionService,
    ) {
        this.route.queryParamMap.subscribe((paramMap) => {
            const category = paramMap.get('category');
            if (category) {
                const tabIndex = this.mainGroups.findIndex((group) => {
                    return group.name.toLowerCase() === category.toLowerCase();
                });

                if (tabIndex >= 0) {
                    this.activeTabIndex = tabIndex;
                }
            }
            this.addTab();
        });
    }

    ngOnInit() {
        this.reportDefinitionService.GetAll(null).subscribe(
            (result) => this.showReports(<any>result),
            (err) => this.errorService.handle(err),
        );

        this.searchControl = new UntypedFormControl();
        this.controlSubscription = this.searchControl.valueChanges.subscribe(() => {
            this.filterReports();
        });
    }

    ngOnDestroy() {
        this.controlSubscription?.unsubscribe();
    }

    private showReports(reports: Array<IReport>) {
        reports.sort((a, b) => (a.Name > b.Name ? 1 : a.Name === b.Name ? 0 : -1));
        reports.forEach((element) => {
            if (element.Visible || element.IsQuery) {
                this.placeReport(<IReport>element);
            }
        });
        this.mainGroups.forEach((mg) => {
            mg.groups.sort((a, b) => ((a.reports?.length || 0) > (b.reports?.length || 0) ? -1 : 1));
        });

        this.mainGroups.forEach((mg) => {
            mg.groups.forEach((g) => {
                const user = this.authService.currentUser;
                const hasFeaturePermission =
                    !g.featurePermission || this.featurePermissionService.canShowUiFeature(g.featurePermission);
                const hasRoutePermission =
                    !g.routePermission || this.authService.canActivateRoute(user, g.routePermission);
                g['_hidden'] = !hasFeaturePermission || !hasRoutePermission;
            });
        });

        this.filterMainGroups();
    }

    public addTab() {
        this.pageStateService.setPageState('category', this.mainGroups[this.activeTabIndex].name);

        this.tabService.addTab({
            name: 'Rapportoversikt',
            url: this.pageStateService.getUrl(),
            moduleID: UniModules.Reports,
            active: true,
        });
    }

    public showReport(report: any) {
        if (report['BizReportType']) {
            this.runBizReport(report);
        } else if (report.IsQuery) {
            this.showUniQuery(report);
        } else {
            this.showReportParams(report);
        }
    }

    filterReports(): void {
        this.filteredMainGroups.forEach((mainGrp) => {
            mainGrp.allGroups ??= [...mainGrp.groups]; // initialize allGroups (first time only)
            mainGrp.count = 0;

            mainGrp.allGroups.forEach((subGrp) => {
                subGrp.allreports ??= [...subGrp.reports]; // initialize allReports (first time only)

                subGrp.reports = [...subGrp.allreports].filter((report) =>
                    this.isMatch(report, this.searchControl.value),
                );

                if (this.searchControl.value) mainGrp.count += subGrp.reports.length;
            });

            mainGrp.groups = mainGrp.allGroups.filter((grp) => grp.reports.length);
        });
    }

    private filterMainGroups() {
        this.filteredMainGroups = this.mainGroups.filter((group) =>
            group.groups.some((subGroup) => this.isVisible(subGroup) && subGroup.reports.length > 0),
        );
    }

    private isVisible(subGroup: ISubGroup): boolean {
        return !(subGroup as ISubGroup & { _hidden?: boolean })._hidden;
    }

    private showReportParams(report: ReportDefinition) {
        if (report.Name.toLowerCase() === 'avstemming') {
            this.openReportModal(UniReportParamsModal, report, (updatedReport) => {
                updatedReport['parameters'].forEach((param) => {
                    if (param.Name === 'includeNotPayed' || param.Name === 'onlyBooked') {
                        param['value'] = param['value'] === true || param['value'] === 1;
                    }
                });
                return updatedReport;
            });

            return;
        }

        switch (report.Name) {
            case 'Utbetalingsliste':
            case 'Konteringssammendrag':
                this.uniModalService.open(SalaryPaymentListModal, { data: report });
                break;
            case 'Kvartalsvis fraværsrapport':
                this.uniModalService.open(AbsenceReportModal, { data: report });
                break;
            case 'Forskuddstrekk og arbeidsgiveravgift':
                this.uniModalService.open(SalaryWithholdingAndAgaModal, { data: report });
                break;
            default:
                this.defaultRunReport(report);
                break;
        }
    }

    private defaultRunReport(report: ReportDefinition) {
        this.uniModalService
            .open(UniReportParamsModal, {
                data: report,
                header: report.Name,
                message: report.Description,
            })
            .onClose.subscribe((modalResult) => {
                if (modalResult === ConfirmActions.ACCEPT) {
                    if (report['BizReportType']) {
                        this.runBizReport(report);
                    } else {
                        this.uniModalService.open(UniPreviewModal, { data: report });
                    }
                }
            });
    }

    private runBizReport(report: ReportDefinition) {
        let parameters = '';
        const inputs = <{ Name: string; value: any }[]>report['parameters'];
        inputs?.forEach((par) => {
            if (par.value) {
                parameters += (parameters.length ? '&' : '?') + `${par.Name}=${par.value}`;
            }
        });
        this.router.navigateByUrl('/reports/new/' + report.ID + parameters);
    }

    private openReportModal(
        type: Type<IUniModal>,
        report: ReportDefinition,
        handleReport?: (updatedReport: ReportDefinition) => ReportDefinition,
    ) {
        this.uniModalService
            .open(type, {
                data: report,
                header: report.Name,
                message: report.Description,
            })
            .onClose.pipe(filter((modalResult) => modalResult === ConfirmActions.ACCEPT))
            .pipe(map(() => (handleReport ? handleReport(report) : report)))
            .subscribe((rep) =>
                this.uniModalService.open(UniPreviewModal, {
                    data: rep,
                }),
            );
    }

    private showUniQuery(report: UniQueryDefinition) {
        this.router.navigateByUrl('/uniqueries/details/' + report.ID);
    }

    private placeReport(report: IReport) {
        for (let i = 0; i < this.mainGroups.length; i++) {
            const match = this.mainGroups[i].groups.find(
                (x) => x.label === report.Category || (x.keywords && x.keywords.indexOf(report.Category) >= 0),
            );
            if (match) {
                match.reports.push(report);
                return;
            }
        }

        // Category not found (put into "custom")
        const main = this.mainGroups.find((x) => x.name === 'Custom');
        if (report.Category) {
            const grp = main.groups.find((g) => g.label === report.Category || g.name === report.Category);
            if (grp) {
                grp.reports.push(report);
            } else {
                main.groups.push({ name: report.Category, label: report.Category, reports: [report] });
            }
        } else {
            main.groups.find((x) => x.name === 'Custom').reports.push(report);
        }
    }

    private isMatch(rep: IReport, filter: string) {
        return !filter || rep.Name?.toLowerCase().includes(filter.toLowerCase());
    }
}
