import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { IComplexInput } from '../../inputarray/inputarraycomponent';
import { KeyCodes } from '@app/services/common/keyCodes';

@Component({
    selector: 'range-input',
    templateUrl: './range-input.html',
    styleUrls: ['./range-input.sass'],
})
export class RangeInput {
    @Input() input: IComplexInput;
    @Output() valueChange = new EventEmitter();
    @ViewChild('fromNumber') fromNumber: ElementRef<HTMLInputElement>;
    @ViewChild('toNumber') toNumber: ElementRef<HTMLInputElement>;

    public fromValue: string;
    public toValue: string;

    ngOnInit() {
        if (this.input?.mapsTo?.length === 2) {
            this.fromValue = this.input?.mapsTo[0].Default;
            this.toValue = this.input?.mapsTo[1].Default;
        }
    }

    cleanupStringValue(value: string): string {
        if (value === undefined || value === '') return '';
        return value.replace(/'/g, '');
    }

    onKeyDown(src, event: KeyboardEvent) {
        const range = this.parseValues();
        const key = event.which || event.keyCode;
        switch (key) {
            case KeyCodes.ENTER:
                if (range.from === 0 || (range.from > 0 && range.to > 0)) {
                    this.submitRange();
                } else {
                    if (src === 'from') this.toNumber.nativeElement.focus();
                    if (src === 'to') this.submitRange();
                }
                event.stopPropagation();
                break;

            case KeyCodes.TAB:
                if (src === 'to' && !event.shiftKey) {
                    this.submitRange();
                    return;
                }
                event.stopPropagation();
                break;
        }
    }

    parseValues(): { from: number; to: number } {
        const fromNumber = parseInt(this.fromNumber.nativeElement.value);
        const toNumber = parseInt(this.toNumber.nativeElement.value);
        return { from: isNaN(fromNumber) ? 0 : fromNumber, to: isNaN(toNumber) ? 0 : toNumber };
    }

    submitRange() {
        const range = this.parseValues();
        this.valueChange.emit(range);
    }
}
