import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UniTableColumn, UniTableConfig } from '@uni-framework/ui/unitable';
import { IUniTab } from '@uni-framework/uni-tabs';
import { IBizReport, IBizReportColumn, IBizReportLayout } from '../../IBizReport';

@Component({
    selector: 'biz-report-layout-editor',
    templateUrl: './LayoutEditor.html',
})
export class BizReportLayoutEditorComponent {
    @Input() report: IBizReport;
    @Output() reportChange = new EventEmitter<IBizReport>();

    layoutConfig: UniTableConfig;
    currentLayout: IBizReportLayout;
    currentLayoutIndex: number = 0;
    columns: IBizReportColumn[] = [];
    layoutSelectConfig = {
        template: (item) => item.Label,
        searchable: false,
        hideDeleteButton: true,
    };
    tabs: IUniTab[] = [];

    constructor() {
        this.layoutConfig = this.createLayoutConfig();
    }

    ngOnInit() {
        if (this.report?.Layouts?.length > 0) {
            this.tabs = this.report.Layouts.map((l) => {
                return { name: l.Name || 'Mainlayout' };
            });
            this.showLayout(this.currentLayoutIndex);
        }
    }

    private showLayout(index: number) {
        this.currentLayoutIndex = index;
        this.currentLayout = this.report.Layouts[index];
        this.columns = this.currentLayout.Columns.map((x) => {
            return { ...x };
        });
    }

    private createLayoutConfig(): UniTableConfig {
        return new UniTableConfig('reporteditor.layouteditor', true)
            .setDeleteButton(true)
            .setColumns([
                new UniTableColumn('Source', 'Source'),
                new UniTableColumn('Label', 'Label'),
                new UniTableColumn('Format', 'Format'),
                new UniTableColumn('Drilldown', 'Drilldown'),
                new UniTableColumn('Max', 'Max').setWidth('3rem'),
            ])
            .setChangeCallback((event: { rowModel: any; field: string; newValue: string; originalIndex: number }) => {
                const item = event.rowModel;
                const array = this.currentLayout.Columns;
                if (event.originalIndex < array.length) {
                    const match = array[event.originalIndex];
                    match[event.field] = event.newValue;
                    this.onReportChanged();
                } else {
                    array.push(this.mapInto(item, ['Source', 'Label', 'Format', 'Drilldown', 'Max']));
                }
                return item;
            });
    }

    onColumnDelete(event: { _originalIndex: number }) {
        if (event._originalIndex >= 0) {
            this.currentLayout.Columns.splice(event._originalIndex, 1);
            this.onReportChanged();
        }
    }

    onReportChanged() {
        this.reportChange.emit(this.report);
    }

    onTabClick(index: number) {
        this.showLayout(index);
    }

    private mapInto(item: any, props: string[]): any {
        const result = {};
        props.forEach((prop) => {
            result[prop] = item[prop];
        });
        return result;
    }
}
