import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import { IBizReportChange } from './BizReportEditorComponent';

export interface IReportEditConfig {
    reportId?: number;
    json: string;
    updated: boolean;
}

@Component({
    selector: 'uni-report-editor-modal',
    template: `
        <section role="dialog" class="uni-modal" style="width: 75vw;min-height: 36rem;">
            <header>Edit report</header>

            <article class="scrollable">
                <biz-report-editor [json]="state.json" (reportChange)="onChange($event)"></biz-report-editor>
            </article>

            <footer>
                <button class="secondary" (click)="onClose.emit()">Avbryt</button>
                <button class="c2a" (click)="save()">Bruk</button>
            </footer>
        </section>
    `,
})
export class BizReportEditorModal implements IUniModal {
    @Input() options: IModalOptions = {};
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    state: IReportEditConfig;

    ngOnInit() {
        this.state = this.options.data;
        this.state.updated = false;
    }

    onChange(event: IBizReportChange) {
        this.state.json = event.json;
    }

    save() {
        setTimeout(() => {
            let result = this.state;
            this.state.updated = true;
            this.onClose.emit(result);
        }, 50);
    }
}
