import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subscription, fromEvent } from 'rxjs';
import { DrilldownPreviewConfig } from './drilldown-configs';

export class DrilldownPopup {
    private overlayService: Overlay;
    private overlayRef: OverlayRef;
    private hoverSubscriptions: Subscription[];
    private backdropClickSubscription: Subscription;
    private mutationObserver: MutationObserver;

    constructor(
        overlayService: Overlay,
        anchor: HTMLElement,
        config: DrilldownPreviewConfig,
        drilldownRoute?: { url: string; queryParams?: any },
    ) {
        this.overlayService = overlayService;

        this.overlayRef = this.overlayService.create(
            new OverlayConfig({
                positionStrategy: this.getPositionStrategy(anchor),
                maxHeight: '25rem',
                hasBackdrop: true,
                backdropClass: ['report-drilldown-backdrop'],
            }),
        );

        const componentPortal = new ComponentPortal<any>(config.component);
        const componentRef = this.overlayRef.attach(componentPortal);

        componentRef.instance.params = config.params;
        componentRef.instance.drilldownRoute = drilldownRoute;
        componentRef.instance.enableStickyMode = () => this.enableStickyMode();

        this.setupListeners(anchor, this.overlayRef.overlayElement);
    }

    destroy() {
        this.overlayRef?.detach();
        this.overlayRef?.dispose();
        this.mutationObserver?.disconnect();
        this.hoverSubscriptions?.forEach((sub) => sub.unsubscribe());
        this.hoverSubscriptions = [];
        this.backdropClickSubscription?.unsubscribe();
    }

    private enableStickyMode() {
        if (!this.overlayRef) return;

        this.hoverSubscriptions?.forEach((sub) => sub.unsubscribe());
        this.hoverSubscriptions = [];

        this.overlayRef.backdropElement.classList.add('active');
    }

    private setupListeners(anchor: HTMLElement, overlay: HTMLElement) {
        let cellHover = true;
        let popupHover = false;
        let timeout;

        const onMouseleave = () => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                if (!cellHover && !popupHover) {
                    this.destroy();
                }
            }, 250);
        };

        // Remove if the user moves their cursor away from both the cell and the popup
        this.hoverSubscriptions = [
            fromEvent(anchor, 'mouseenter').subscribe(() => (cellHover = true)),
            fromEvent(anchor, 'mouseleave').subscribe(() => {
                cellHover = false;
                onMouseleave();
            }),

            fromEvent(overlay, 'mouseenter').subscribe(() => (popupHover = true)),
            fromEvent(overlay, 'mouseleave').subscribe(() => {
                popupHover = false;
                onMouseleave();
            }),
        ];

        this.backdropClickSubscription = this.overlayRef.backdropClick().subscribe(() => {
            this.destroy();
        });

        // Using a mutation observer to detect dom changes inside the overlay element,
        // typically when the content is rendered. When this happens we update the
        // positionStrategy which causes the overlay to re-calculate size and position.
        // This fixes an issue where the dropdown was unable to increase it's height
        // even though we had plenty of free vertical space.
        this.mutationObserver = new MutationObserver(() => {
            this.overlayRef.updatePositionStrategy(this.getPositionStrategy(anchor));
        });

        this.mutationObserver.observe(overlay, {
            childList: true,
            subtree: true,
        });
    }

    private getPositionStrategy(anchor: HTMLElement) {
        return this.overlayService
            .position()
            .flexibleConnectedTo(anchor)
            .withPush(false)
            .withPositions([
                { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
                { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
                { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
                { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
            ]);
    }
}
