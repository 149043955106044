<uni-toolbar [config]="{ title: 'Rapportoversikt' }"></uni-toolbar>

<section class="application">
    <uni-tabs
        [tabs]="filteredMainGroups"
        [labelProperty]="'label'"
        [(activeIndex)]="activeTabIndex"
        (activeIndexChange)="addTab()"
    >
    </uni-tabs>

    <div class="searchcontainer">
        <input class="search" [formControl]="searchControl" type="search" placeholder="Søk" />
    </div>

    <section class="report-groups">
        @for (group of filteredMainGroups[activeTabIndex]?.groups; track group.name) {
            <ng-container>
                @if (group.reports?.length > 0 && !group._hidden) {
                    <section class="uni-container report-group flex-column align-center">
                        <header>{{ group.label }}</header>
                        @for (report of group.reports; track $index) {
                            <button
                                (click)="showReport(report)"
                                [class.filtered]="report.Filtered"
                                [tabindex]="report.Filtered ? -1 : 0"
                                [title]="report.Name"
                                class="tertiary c2a report justify-start"
                            >
                                <span class="ellipsis">{{ report.Name }}</span>
                                @if (report.BizReportType) {
                                    <div class="newbadge">Ny</div>
                                }
                            </button>
                        }
                    </section>
                }
            </ng-container>
        }
    </section>
</section>

<router-outlet></router-outlet>
