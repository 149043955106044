import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ImageModal } from '@app/components/common/modals/ImageModal';
import { DimensionSettingsService } from '@app/services/common/dimensionSettingsService';
import { ErrorService } from '@app/services/common/errorService';
import { StatisticsService } from '@app/services/common/statisticsService';
import { UniModalService } from '@uni-framework/uni-modal';

@Component({
    selector: 'journal-entries-drilldown',
    templateUrl: './journal-entries-drilldown.html',
    styleUrls: ['./journal-entries-drilldown.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JournalEntriesDrilldown {
    drilldownRoute: { url: string; queryParams?: any };
    params: any;
    enableStickyMode: () => void;

    filterTags: string[];
    dataLoaded: boolean;
    data: any[];

    constructor(
        private errorService: ErrorService,
        private cdr: ChangeDetectorRef,
        private statisticsService: StatisticsService,
        private modalService: UniModalService,
        private dimensionSettingsService: DimensionSettingsService,
    ) {}

    ngOnInit() {
        if (this.params) {
            this.loadData();
        }
    }

    private async loadData() {
        const select = [
            'ID as ID',
            'JournalEntryNumberNumeric as JournalEntryNumberNumeric',
            'JournalEntryID as JournalEntryID',
            'Description as Description',
            'AmountCurrency as AmountCurrency',
            'FinancialDate as FinancialDate',
            `sum(casewhen(FileEntityLink.EntityType eq \'JournalEntry\'\\,1\\,0)) as AttachmentCount`,
        ].join(',');

        const expand = 'Period,Account,Dimensions.Info';
        const join = 'JournalEntryLine.JournalEntryID eq FileEntityLink.EntityID';

        const params = this.params || {};

        const filterTags = [`År: ${params.AccountYear}`, `Periode: ${params.FromPeriod}-${params.ToPeriod}`];

        let filter =
            `isnull(StatusCode,0) ne 31004` +
            ` and Account.AccountNumber eq ${params.AccountNumber}` +
            ` and Period.AccountYear eq ${params.AccountYear} and Period.No ge ${params.FromPeriod} and Period.No le ${params.ToPeriod}`;

        if (params.ProjectNumber) {
            filter += ` and Info.ProjectNumber eq '${params.ProjectNumber}'`;
            filterTags.push(`Prosjekt: ${params.ProjectNumber}`);
        }

        if (params.DepartmentNumber) {
            filter += ` and Info.DepartmentNumber eq '${params.DepartmentNumber}'`;
            filterTags.push(`Avdeling: ${params.DepartmentNumber}`);
        }

        const [customDimFilter, customDimFilterTags] = await this.getCustomDimensionFilters(params);
        if (customDimFilter) {
            filter += ` and (${customDimFilter}) `;
            filterTags.push(...(customDimFilterTags || []));
        }

        this.filterTags = filterTags;

        const query = `model=JournalEntryLine&top=50&expand=${expand}&join=${join}&select=${select}&filter=${filter}&orderby=ID desc`;

        this.statisticsService.GetAllUnwrapped(query).subscribe({
            next: (data) => {
                this.data = data;
                this.dataLoaded = true;
                this.cdr.markForCheck();
            },
            error: (err) => this.errorService.handle(err),
        });
    }

    private async getCustomDimensionFilters(params) {
        let filters = [];
        let filterTags = [];

        for (let [key, value] of Object.entries(params)) {
            if (value) {
                key = key.toLowerCase();

                if (key === 'projectnumber') {
                    filters.push(`isnull(Info.ProjectNumber,0) eq '${value}'`);
                } else if (key === 'departmentnumber') {
                    filters.push(`isnull(Info.DepartmentNumber,0) eq '${value}'`);
                } else if (key.startsWith('dimension')) {
                    filters.push(`isnull(Info.${key},0) eq '${value}'`);
                    const label = await this.dimensionSettingsService.getDimensionName(key.replace('number', ''));
                    if (label) {
                        filterTags.push(`${label}: ${value}`);
                    }
                }
            }
        }

        if (!filters.length) return [];

        return [filters.join(' and '), filterTags];
    }

    showAttachments(row) {
        if (row.JournalEntryID) {
            this.modalService.open(ImageModal, {
                data: {
                    entity: 'JournalEntry',
                    entityID: row.JournalEntryID,
                    readonly: true,
                },
            });

            this.enableStickyMode();
        }
    }
}
