<input
    class="flex-1"
    [(ngModel)]="fromValue"
    #fromNumber
    (keydown)="onKeyDown('from', $event)"
    placeholder="Fra nr."
    type="text"
/>
<input
    class="flex-1"
    [(ngModel)]="toValue"
    #toNumber
    (keydown)="onKeyDown('to', $event)"
    placeholder="Til nr."
    type="text"
/>
