import { NgModule } from '@angular/core';

import { LayoutModule } from '../layout/layoutModule';
import { UniFrameworkModule } from '../../../framework/frameworkModule';
import { AppCommonModule } from '../common/appCommonModule';

import { UniReports } from './reports';
import { UniReportParamsModal } from './modals/parameter/reportParamModal';
import { UniReportComments } from '@app/components/reports/modals/parameter/reportComments';
import { UniReportSendModal } from '@app/components/reports/modals/parameter/reportSendModal';
import { SalaryWithholdingAndAgaModal } from './modals/salary-withholding-and-aga/salary-withholding-and-aga';
import { SalaryPaymentListModal } from './modals/salary-payment-list/salary-payment-list';
import { BizReportComponent } from './bizreport/BizReportComponent';
import { InputArrayComponent } from './bizreport/inputarray/inputarraycomponent';
import { ReportFilterDropdown } from './bizreport/filter-dropdown/filter-dropdown';
import { BizReportEditorModal } from './bizreport/editor/EditorModal';
import { BizReportEditorComponent } from './bizreport/editor/BizReportEditorComponent';
import { BizReportLayoutEditorComponent } from './bizreport/editor/layout/LayoutEditor';
import { BizReportService } from './bizreport/biz-report-service';
import { ReportSettingsDropdown } from './bizreport/settings-dropdown/report-settings-dropdown';
import { DrilldownService } from './bizreport/drilldown/drilldown-service';
import { AbsenceReportModal } from './modals/absence-report/absence-report';
import { JournalEntriesDrilldown } from './bizreport/drilldown/journal-entries/journal-entries-drilldown';
import { OpenPostsDrilldown } from './bizreport/drilldown/open-posts/open-posts-drilldown';
import { ReportParamSearchService } from './report-param-search-service';
import { RangeInput } from './bizreport/filter-dropdown/range-input/range-input';
import { SoldProductsDrilldownComponent } from './bizreport/drilldown/sold-products-drilldown/sold-products-drilldown.component';
import { ReportCommentEditor } from './modals/parameter/reportCommentEditor';
import { TextGenerationSettingsModal } from './modals/parameter/textGenerationSettingsModal';
import { TextTemplates } from './modals/parameter/text-templates/text-templates';
import { ReportTemplateEditorModal } from './modals/parameter/report-template-editor-modal/report-template-editor-modal';

@NgModule({
    imports: [UniFrameworkModule, LayoutModule, AppCommonModule],
    declarations: [
        UniReports,
        UniReportParamsModal,
        AbsenceReportModal,
        UniReportComments,
        ReportCommentEditor,
        TextGenerationSettingsModal,
        UniReportSendModal,
        SalaryWithholdingAndAgaModal,
        SalaryPaymentListModal,
        BizReportComponent,
        InputArrayComponent,
        ReportFilterDropdown,
        BizReportEditorComponent,
        BizReportEditorModal,
        BizReportLayoutEditorComponent,
        ReportSettingsDropdown,
        JournalEntriesDrilldown,
        OpenPostsDrilldown,
        RangeInput,
        SoldProductsDrilldownComponent,
        TextTemplates,
        ReportTemplateEditorModal,
    ],
    providers: [BizReportService, DrilldownService, ReportParamSearchService],
    exports: [
        UniReports,
        UniReportParamsModal,
        AbsenceReportModal,
        SalaryWithholdingAndAgaModal,
        SalaryPaymentListModal,
    ],
})
export class ReportsModule {}
