import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, tap, map, distinctUntilChanged } from 'rxjs/operators';
import { BizReportService } from '../biz-report-service';
import { IBizReport, IBizReportLayout } from '../IBizReport';
import { DrilldownData, DRILLDOWN_CONFIGS, ReportDrilldownConfig } from './drilldown-configs';
import { Router } from '@angular/router';
import { ReportData } from '../ReportData';
import { DrilldownPopup } from './drilldown-popup';

@Injectable()
export class DrilldownService {
    private gridContainer: HTMLElement;
    private report: IBizReport;
    private layout: IBizReportLayout;

    private clickSubscription: Subscription;
    private mousemoveSubscription: Subscription;
    private mouseleaveSubscription: Subscription;

    private drilldownConfig: ReportDrilldownConfig;
    private hoveredCell: HTMLTableCellElement;
    private popup: DrilldownPopup;

    hasPopupDrilldown: boolean;
    popupDrilldownEnabled: boolean;

    constructor(
        private reportService: BizReportService,
        private overlayService: Overlay,
        private router: Router,
    ) {
        const popupEnabled = localStorage.getItem('popup_drilldown_enabled');
        this.popupDrilldownEnabled = popupEnabled ? JSON.parse(popupEnabled) : true;
    }

    connect(gridContainer, report: IBizReport, layout: IBizReportLayout) {
        this.disconnect();

        this.gridContainer = gridContainer;
        this.report = report;
        this.layout = layout;
        this.drilldownConfig = DRILLDOWN_CONFIGS[this.report.Name];

        this.clickSubscription = fromEvent(gridContainer, 'click').subscribe((event) =>
            this.goToDrilldownView(event as MouseEvent),
        );

        this.hasPopupDrilldown = !!this.drilldownConfig?.preview;

        if (this.hasPopupDrilldown && this.popupDrilldownEnabled) {
            this.setupPopupListeners();
        }
    }

    disconnect() {
        this.popup?.destroy();
        this.clickSubscription?.unsubscribe();
        this.mousemoveSubscription?.unsubscribe();
        this.mouseleaveSubscription?.unsubscribe();
    }

    setPopupDrilldownEnabled(enabled: boolean) {
        this.popupDrilldownEnabled = enabled;
        localStorage.setItem('popup_drilldown_enabled', JSON.stringify(this.popupDrilldownEnabled));

        if (enabled) {
            this.setupPopupListeners();
        } else {
            this.mousemoveSubscription?.unsubscribe();
            this.mouseleaveSubscription?.unsubscribe();
        }
    }

    private setupPopupListeners() {
        if (!this.hasPopupDrilldown || !this.popupDrilldownEnabled) return;

        // Show drilldown preview when hovering a cell/row
        this.mousemoveSubscription = fromEvent(this.gridContainer, 'mousemove')
            .pipe(
                map((event: MouseEvent) => {
                    const target = event.target as HTMLElement;
                    return (target.tagName === 'TD' ? target : target.closest('td')) as HTMLTableCellElement;
                }),
                distinctUntilChanged(),
                tap((cell) => (this.hoveredCell = cell)),
                debounceTime(750),
            )
            .subscribe((cell) => {
                if (cell && cell === this.hoveredCell) {
                    this.popup?.destroy();
                    const data = this.getDrilldownData(cell);

                    if (data) {
                        const previewConfig = this.drilldownConfig.preview(data);
                        const route = this.drilldownConfig.routeResolver(data);
                        this.popup = new DrilldownPopup(this.overlayService, cell, previewConfig, route);
                    }
                }
            });

        this.mouseleaveSubscription = fromEvent(this.gridContainer, 'mouseleave').subscribe(
            () => (this.hoveredCell = undefined),
        );
    }

    private goToDrilldownView(event: MouseEvent) {
        const target = event.target as HTMLElement;
        const cell = target.tagName === 'TD' ? target : target.closest('td');
        const drilldownData = cell && this.getDrilldownData(cell as HTMLTableCellElement);

        if (!drilldownData) return;

        if (this.drilldownConfig?.routeResolver) {
            const { url, queryParams } = this.drilldownConfig.routeResolver(drilldownData);
            this.router.navigate([url], { queryParams });
        } else {
            const route = ReportData.interpolate(
                drilldownData.colDef?.Drilldown || this.layout.Drilldown,
                { ...drilldownData.rowData, ...drilldownData.filterValues },
                true,
            );
            if (route) {
                this.router.navigateByUrl(route);
            }
        }
    }

    private getDrilldownData(cell: HTMLTableCellElement): DrilldownData {
        const row: HTMLTableRowElement = cell?.closest('tr');

        // Ignore group header/footer rows
        if (!row || row.getAttribute('data-group')) {
            return;
        }

        const rowIndex = parseInt(row.getAttribute('data-index'));

        const { index } = ReportData.locateRow(this.layout.Dataset, this.report.Data.routes) || {};
        const datasets = this.reportService.data.dataSets;
        const dataset = datasets[index || 0];

        const filterValues: { [name: string]: any } = (this.report.Input || []).reduce((values, input) => {
            if (input.Name && input.Default) {
                values[input.Name] = input.Default;
            }

            return values;
        }, {});

        return {
            rowData: dataset && dataset[rowIndex],
            colDef: this.layout.Columns[cell.cellIndex],
            filterValues,
        };
    }
}
