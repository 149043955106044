<section class="dropdown-menu report-drilldown-popup custom-scrollbar" *ngIf="dataLoaded">
    <h1 class="flex align-center gap-3">
        Faktura med produkt:

        <span *ngIf="params?.productName && params?.productID" class="filter-tag border rounded">
            {{ params.productNumber }} - {{ params.productName }}
        </span>
    </h1>

    <table>
        <tr>
            <th>Fakturanummer</th>
            <th>Kundenavn.</th>
            <th class="date">Fakturadato</th>
            <th class="number">Antall</th>
            <th class="number">FakturaSum</th>
        </tr>

        <ng-container *ngFor="let rows of data">
            <tr *ngFor="let row of rows.Items" [routerLink]="['/sales/invoices/', rows.ID]">
                <td class="number">
                    <a>{{ rows.ID }}</a>
                </td>
                <td class="description">{{ rows.CustomerName }}</td>
                <td class="date">{{ rows.InvoiceDate | date: 'dd.MM.yy' }}</td>
                <td class="number">{{ row.NumberOfItems }}</td>
                <td class="money">{{ row.SumTotalExVat }}</td>
            </tr>
        </ng-container>
    </table>
</section>
