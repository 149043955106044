<section>
    <!-- <label>{{subTitle}}</label> -->

    <label *ngIf="dateSelector.use">
        <span class="label-above">Periode</span>
        <date-range-picker
            [periodOnly]="!!dateSelector.periodOnly"
            [to]="dateSelector.toDate"
            [from]="dateSelector.fromDate"
            (change)="onPeriodSelectorChange($event)"
        >
        </date-range-picker>
    </label>

    <label *ngIf="layouts?.length > 1">
        <span class="label-above">Visning</span>
        <uni-select
            [value]="currentLayout"
            [items]="layouts"
            [config]="layoutSelectConfig"
            (valueChange)="onSelectLayout($event)"
        >
        </uni-select>
    </label>

    <filter-dropdown
        *ngIf="complexInputs?.length"
        [inputs]="complexInputs"
        [showInline]="inputCount <= 3"
        (inputChange)="inputChange.emit($event)"
    ></filter-dropdown>

    <report-settings-dropdown
        class="pull-right"
        [report]="report"
        [layout]="currentLayout"
        [flipSignInput]="flipSignInput"
        (flipSignChange)="inputChange.emit($event)"
        (viewSettingChange)="viewSettingChange.emit()"
    >
    </report-settings-dropdown>
</section>
