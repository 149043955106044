<section class="dropdown-menu report-drilldown-popup custom-scrollbar" *ngIf="dataLoaded">
    <h1>{{ params?.AccountNumber }} - {{ params?.AccountName }}</h1>

    <section *ngIf="filterTags" class="flex gap-1 mb-2">
        <span *ngFor="let tag of filterTags" class="filter-tag border rounded">{{ tag }}</span>
    </section>

    <table>
        <tr>
            <th>Bilagsnr.</th>
            <th>Beskrivelse</th>
            <th class="date">Dato</th>
            <th class="number">Beløp</th>
            <th class="attachment"></th>
        </tr>

        <tr *ngFor="let row of data">
            <td>
                <a
                    routerLink="/accounting/transquery"
                    [queryParams]="{
                        JournalEntryNumber: row.JournalEntryNumberNumeric,
                        AccountYear: params.AccountYear,
                        ShowCrediteLined: false,
                    }"
                >
                    {{ row.JournalEntryNumberNumeric }}
                </a>
            </td>
            <td>{{ row.Description }}</td>
            <td class="date">{{ row.FinancialDate | date: 'dd.MM.yyyy' }}</td>
            <td class="number">{{ row.AmountCurrency | uninumberformat: 'money' }}</td>
            <td class="attachment">
                <i *ngIf="row.AttachmentCount > 0" (click)="showAttachments(row)" class="material-icons" role="button">
                    attachment
                </i>
            </td>
        </tr>
    </table>

    <section *ngIf="drilldownRoute" class="mt-3">
        {{ data?.length === 50 ? 'Visningen er begrenset til siste 50 elementer, gå til ' : 'Gå til ' }}

        <a [routerLink]="[drilldownRoute.url]" [queryParams]="drilldownRoute.queryParams"> detaljert visning </a>

        for å se mer.
    </section>
</section>
