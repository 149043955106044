import { Component, EventEmitter, Input, Output } from '@angular/core';
import { of, forkJoin, Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { IBizReport, IBizReportInput, IBizReportLayout, IBizReportSettings } from '../IBizReport';
import { ReportRender } from '../ReportRenderer';
import { capitalizeFirstLetter } from '@app/components/common/utils/utils';
import { AutocompleteOptions } from '@uni-framework/ui/autocomplete/autocomplete';
import { ReportParamSearchConfig, ReportParamSearchService } from '../../report-param-search-service';
import { DateRangePickerChangeEvent } from '@uni-framework/ui/date-range-picker/date-range-picker';
import { DimensionSettings } from '@uni-entities';

import { rigDate } from '@app/components/common/utils/rig-date';
import { CustomDimensionService } from '@app/services/common/customDimensionService';
import { StatisticsService } from '@app/services/common/statisticsService';

export interface IInputChange {
    layout: IBizReportLayout;
    inputs: IBizReportInput[];
    action?: 'edit-options';
}

export interface IComplexInput {
    name: string;
    text: string;
    type?: 'Number' | 'Boolean' | 'Date' | 'Select' | 'Dropdown';
    label?: string;
    mapsTo: IBizReportInput[];
    initValue?: any;
    value?: any;
    autoCompleteOptions?: AutocompleteOptions;
    hasEditor: boolean;
    range?: 'on' | 'off' | undefined;
}

@Component({
    selector: 'input-array',
    templateUrl: './inputarraycomponent.html',
    styleUrls: ['./inputarraycomponent.sass'],
})
export class InputArrayComponent {
    @Input() report: IBizReport;
    @Input() settings: IBizReportSettings;

    @Output() inputChange = new EventEmitter<IInputChange>();
    @Output() viewSettingChange = new EventEmitter<string>();

    inputCount: number;
    complexInputs: Array<IComplexInput> = [];
    flipSignInput: IComplexInput;
    subTitle: string;
    layouts: Array<IBizReportLayout> = [];
    dateSelector: { use: boolean; fromDate?: Date; toDate?: Date; inputs: IBizReportInput[]; periodOnly: boolean } = {
        use: false,
        inputs: [],
        periodOnly: true,
    };
    currentLayout: IBizReportLayout;
    layoutSelectConfig = {
        template: (item) => item.Label,
        searchable: false,
        hideDeleteButton: true,
    };
    private cachedDimensionData: Observable<any>;

    constructor(
        private statisticsService: StatisticsService,
        private reportParamSearchService: ReportParamSearchService,
        private customDimensionService: CustomDimensionService,
    ) {}

    async analyzeInputs(report: IBizReport, routeParams: any, settings: IBizReportSettings) {
        this.report = report;
        this.layouts = report.Layouts;
        this.currentLayout = report.Layouts[settings.layoutIndex];

        /*
            REVISIT: this should probably run in BizReportComponent or service instead.
            Currently we're depending on inputarraycommponent mutating the
            report inputs for the report to actually work in some cases.
        */
        this.setDefaultValuesOnInputs(report, routeParams, settings);

        const { complexInputs, yearPeriod, allParams } = await this.getInputData(report);
        this.complexInputs = this.flagRanges(complexInputs);

        // Use dateselector instead of year- and periodinputs ?
        if (yearPeriod.year > 0 && yearPeriod.fromPeriod > 0) {
            this.dateSelector.fromDate = new Date(yearPeriod.year, yearPeriod.fromPeriod - 1, 1);
            this.dateSelector.toDate = rigDate(new Date(yearPeriod.year, yearPeriod.toPeriod - 1, 1))
                .endOf('month')
                .toDate();
            this.dateSelector.use = true;

            // Remove complexInput's
            for (let i = yearPeriod.indexes.length - 1; i >= 0; i--) {
                this.complexInputs.splice(yearPeriod.indexes[i], 1);
            }
        } else if (this.complexInputs.length === 0 && report.Input.length > 0) {
            // No inputs / filters available ?
            this.complexInputs.push({ name: 'settings', text: 'Utvalg', mapsTo: [], hasEditor: false });
        }

        // Use periodselector for (from-/todate) ?
        this.complexInputs.forEach((cip) => {
            if (cip.type === 'Date') {
                if (cip.mapsTo.length > 1) {
                    this.dateSelector.use = true;
                    this.dateSelector.periodOnly = false;
                    this.dateSelector.inputs = cip.mapsTo;
                    this.complexInputs.splice(this.complexInputs.indexOf(cip), 1);
                    // Ensure from-/todate is passed to date-selector from defaults or route
                    // (also sorts them so that the range makes sence)
                    const [fromDate, toDate] = this.dateSelector.inputs
                        .map((input) => input.Default && new Date(input.Default))
                        .sort((a, b) => (a?.getTime() || 0) - (b?.getTime() || 0));
                    this.dateSelector.fromDate = fromDate || new Date(`${settings.financialYear}-01-01`);
                    this.dateSelector.toDate = toDate || new Date(`${settings.financialYear}-12-31`);
                } else {
                    if (!rigDate(cip.value).isValid()) {
                        cip.value = new Date();
                    }
                }
            }
        });

        // Remove flipsign input from array, since we'd rather show it in the settings dropdown
        this.flipSignInput = this.complexInputs.find((input) => input.name === 'flipsign');
        if (this.flipSignInput) {
            this.complexInputs = this.complexInputs.filter((input) => input !== this.flipSignInput);
        }

        // Keep pretty-printed subtitle ( used in csv-export )
        this.subTitle = allParams
            .filter((p) => !!p.value && p.value !== 'Nei')
            .map((m) => `${m.label}: ${m.value}`)
            .join(', ');

        // TODO: more elegant solution for deciding if we should show inputs inline or in dropdown
        this.inputCount = this.complexInputs?.length || 0;
        if (this.dateSelector?.use) this.inputCount++;
        if (this.layouts?.length > 1) this.inputCount++;
    }

    flagRanges(inputs: IComplexInput[]): IComplexInput[] {
        inputs.forEach((c) => {
            if (c.mapsTo.length > 0 && c.mapsTo[0].Range) {
                c.range = this.hasRangeValues(c) ? 'on' : 'off';
            }
        });
        return inputs;
    }

    hasRangeValues(input: IComplexInput): boolean {
        if (input.mapsTo?.length === 2) {
            if (input.mapsTo[0].Default === undefined || input.mapsTo[1].Default === undefined) return false;
            if (input.mapsTo[0].Default === '' || input.mapsTo[1].Default === '') return false;
            if (input.mapsTo[0].Default !== input.mapsTo[1].Default) {
                return true;
            }
        }
        return false;
    }

    private setDefaultValuesOnInputs(report: IBizReport, routeParams: any, settings: IBizReportSettings) {
        report.Input.forEach((input) => {
            if (routeParams[input.Name]) {
                input.Default = routeParams[input.Name];
            }

            if (input.SearchModel?.toLowerCase().startsWith('financialyear.year') && isNaN(parseInt(input.Default))) {
                input.Default = settings.financialYear.toString();
            }

            if (input.Type === 'Date') {
                const hasValidDate = input.Default && rigDate(input.Default).isValid();
                if (!hasValidDate) {
                    input.Default = this.getDefaultDate(input, settings);
                }
            }
        });
    }

    getDefaultDate(input: IBizReportInput, settings: IBizReportSettings): string {
        switch (input.Name) {
            case 'fromdate':
            case 'fradato':
            case 'fd':
                return `${settings.financialYear}-01-01`;
            case 'todate':
            case 'tildato':
            case 'td':
                return `${settings.financialYear}-12-31`;
            default:
                return rigDate().format('YYYY-MM-DD');
        }
    }

    private async getInputData(rep: IBizReport) {
        const [activeDimensions, customDimensions] = await this.getDimensionData();

        const response = {
            complexInputs: <IComplexInput[]>[],
            yearPeriod: { year: 0, fromPeriod: 0, toPeriod: 0, indexes: [] },
            allParams: [],
        };

        // Convert inputs to complexInputs or dateselector
        rep.Input.forEach((input) => {
            const complexInput: IComplexInput = {
                name: input.Name,
                label: ReportRender.translate(rep, input.Label),
                text: input.Default || '',
                initValue: input.Default,
                mapsTo: [input],
                type: <any>input.Type,
                hasEditor: false,
            };

            if (input.SearchModel) {
                if (input.SearchModel === 'financialyear.year') {
                    response.yearPeriod.year = parseInt(complexInput.text);
                    response.yearPeriod.indexes.push(response.complexInputs.length);
                    this.dateSelector.inputs.push(input);
                } else {
                    this.initAutoComplete(complexInput, input.SearchModel, customDimensions);
                }
            }

            // Convert values for known types
            switch (input.Type) {
                case 'Date':
                    complexInput.value = rigDate(complexInput.text).toDate();
                    complexInput.text = rigDate(complexInput.text).format('L');
                    complexInput.hasEditor = true;
                    break;
                case 'Boolean':
                    complexInput.value = complexInput.text && complexInput.text !== '0';
                    complexInput.text = complexInput.value ? 'Ja' : 'Nei';
                    complexInput.hasEditor = true;
                    break;
                case 'Select':
                    complexInput.hasEditor = true;
                    const sOptions = this.extractComboData(input);
                    complexInput.value = sOptions.find((x) => x.Value === Number(input.Default));
                    complexInput.autoCompleteOptions = {
                        lookup: (search) => of(sOptions.filter((x) => x.Name.includes(search ?? ''))),
                        displayFunction: (x) => x.Name,
                        openSearchOnClick: true,
                        canClearValue: false,
                    };
                    break;
                case 'Dropdown':
                    complexInput.hasEditor = true;
                    const dOptions = this.extractComboData(input);
                    complexInput.value = dOptions.find((x) => x.Value === Number(input.Default));
                    complexInput['options'] = dOptions;
                    complexInput['config'] = {
                        template: (option) => {
                            return option.Name;
                        },
                        searchable: false,
                        hideDeleteButton: true,
                    };
                    break;
                case 'Number':
                    complexInput.hasEditor = true;
                    complexInput.value = complexInput.text;
                    complexInput.text = complexInput.text;
                    break;
            }

            // Secondary parameter? (pair of from/to)
            const firstOccurrence: IBizReportInput = this.locateFromParam(rep, input);
            if (firstOccurrence) {
                // yes, lets merge:
                const pairedWith = response.complexInputs.find((cip) => cip.name == firstOccurrence.Name);
                if (pairedWith) {
                    const indexFrom = response.complexInputs.indexOf(pairedWith);
                    pairedWith.mapsTo.push(input);

                    // period from/to ?
                    if (this.isFromPeriod(pairedWith)) {
                        response.yearPeriod.fromPeriod = parseInt(pairedWith.text);
                        response.yearPeriod.toPeriod = parseInt(complexInput.text);
                        response.yearPeriod.indexes.push(indexFrom);
                        input.SearchModel = input.SearchModel || 'toperiod';
                        this.dateSelector.inputs.push(input);
                        const inputFrom = rep.Input[indexFrom];
                        inputFrom.SearchModel = inputFrom.SearchModel || 'fromperiod';
                        this.dateSelector.inputs.push(inputFrom);
                    }

                    // identical values ?
                    if (!(pairedWith.text && pairedWith.text == complexInput.text)) {
                        pairedWith.text += ' - ' + complexInput.text;
                        if (pairedWith.text === ' - ') {
                            pairedWith.text = 'alle';
                        }
                    }

                    // modify label (remove "from/to" prefixes)
                    let lab = '';
                    const ixSpace = pairedWith.label.indexOf(' ');
                    if (ixSpace > 0) {
                        lab = pairedWith.label.substring(ixSpace + 1);
                    }
                    pairedWith.label = lab ? capitalizeFirstLetter(lab) : pairedWith.label;
                    response.allParams[indexFrom].label = pairedWith.label;
                    response.allParams[indexFrom].value = pairedWith.text;
                }
            } else {
                let shouldAddInput = true;

                const model = (input.SearchModel || '').split('.')[0];
                if (model === 'project' || model === 'department' || model.startsWith('dimension')) {
                    shouldAddInput = activeDimensions[model];
                }

                if (shouldAddInput) {
                    response.complexInputs.push(complexInput);
                    response.allParams.push({ label: complexInput.label, value: complexInput.text });
                }
            }
        });

        return response;
    }

    extractComboData(input: IBizReportInput): Array<any> {
        if (input.DefaultValueList) {
            return JSON.parse(input.DefaultValueList);
        } else if (input.Options) {
            return input.Options;
        }
        return [];
    }

    private isFromPeriod(input: IComplexInput): boolean {
        if (!input?.name) return false;
        const name = input.name.toLowerCase();
        return name === 'fp' || name === 'fromperiod';
    }

    private locateFromParam(rep: IBizReport, par: IBizReportInput): IBizReportInput {
        const ixCode = par.Label?.indexOf('§to');
        if (ixCode >= 0) {
            const txtSearch = `§from${par.Label.substring(3)}`;
            const match = rep.Input.find((ip) => ip.Label == txtSearch);
            if (match) return match;
        }
        const ixLab = par.Label?.toLowerCase()?.indexOf('til ');
        if (ixLab >= 0) {
            const txtSearch = `fra ${par.Label.substring(4)}`;
            const match = rep.Input.find((ip) => ip.Label.toLowerCase() == txtSearch);
            if (match) return match;
        }
    }

    onInputClick(par: IBizReportInput) {
        // allow user to modify "other" inputs not supported here
        this.inputChange.emit({ inputs: [par], layout: undefined, action: 'edit-options' });
    }

    onDateperiodChanged(event: DateRangePickerChangeEvent) {
        let match = 0;
        let inputs = [];
        for (var i = 0; i < this.dateSelector.inputs.length; i++) {
            const ip = this.dateSelector.inputs[i];
            if (ip.Type === 'Date') {
                if (match == 0) {
                    ip.Default = rigDate(event.fromDate).format('YYYY-MM-DD');
                } else {
                    ip.Default = rigDate(event.toDate).format('YYYY-MM-DD');
                }
                inputs.push(ip);
                if (match++ > 0) {
                    break;
                }
            }
        }
        this.inputChange.emit({ inputs: inputs, layout: undefined });
    }

    onPeriodSelectorChange(event: DateRangePickerChangeEvent) {
        if (this.dateSelector.periodOnly === false) {
            this.onDateperiodChanged(event);
            return;
        }

        const year = event.fromDate.getFullYear();
        const fromPeriod = event.fromDate.getMonth() + 1;

        const toPeriod = event.toDate.getMonth() + 1;

        let routeParams = {};

        const inputs = [];

        this.dateSelector.inputs.forEach((x) => {
            switch (x.SearchModel) {
                case 'financialyear.year':
                    x.Default = year.toString();
                    break;
                case 'fromperiod':
                    x.Default = fromPeriod.toString();
                    break;
                case 'toperiod':
                    x.Default = toPeriod.toString();
                    break;
                default:
                    return;
            }
            routeParams[x.Name] = '' + x.Default;
            inputs.push(x);
        });

        this.inputChange.emit({ inputs: inputs, layout: undefined });
    }

    onSelectLayout(layout: IBizReportLayout) {
        this.inputChange.emit({ inputs: [], layout: layout });
    }

    onSwitch(complexInput: IComplexInput, checked: boolean) {
        complexInput.value = checked;
        complexInput.mapsTo.forEach((x) => (x.Default = '' + (complexInput.value ? 1 : 0)));
        this.inputChange.emit({ inputs: complexInput.mapsTo, layout: undefined });
    }

    private initAutoComplete(input: IComplexInput, searchModel: string, customDimensions: DimensionSettings[]) {
        const [model, valueField] = (searchModel || '').toLowerCase().split('.');

        let searchConfig: ReportParamSearchConfig;

        if (/^dimension(1[0-9]|[1-9])$/.test(model)) {
            // Custom dimensions
            const dimNumber = parseInt(model.replace('dimension', ''));
            const customDim = dimNumber && customDimensions?.find((dim) => dim.Dimension === dimNumber);
            const label = customDim?.Label || model;
            input.label = label;
            searchConfig = this.reportParamSearchService.getDimensionConfig(
                model,
                valueField,
                input.initValue,
                label.toLowerCase(),
            );
        } else {
            // Everything else
            searchConfig = this.reportParamSearchService.getSearchConfig(searchModel, input.initValue, true);
        }

        if (searchConfig) {
            input.autoCompleteOptions = {
                lookup: searchConfig.lookup,
                displayFunction: searchConfig.displayFunction,
                openSearchOnClick: true,
                canClearValue: true,
                showClearButton: true,
            };

            if (searchConfig.getInitData) {
                searchConfig.getInitData().subscribe((res) => {
                    if (res?.length) {
                        input.value = res[0];
                    }
                });
            }

            input.hasEditor = true;
        }
    }

    private getDimensionData() {
        if (!this.cachedDimensionData) {
            const select = [
                `max(casewhen(isnull(ProjectNumber,'') ne '',1,0)) as Projects`,
                `max(casewhen(isnull(DepartmentNumber,'') ne '',1,0)) as Departments`,
                `max(casewhen(isnull(Dimension5Number,'') ne '',1,0)) as Dimension5`,
                `max(casewhen(isnull(Dimension6Number,'') ne '',1,0)) as Dimension6`,
                `max(casewhen(isnull(Dimension7Number,'') ne '',1,0)) as Dimension7`,
                `max(casewhen(isnull(Dimension8Number,'') ne '',1,0)) as Dimension8`,
                `max(casewhen(isnull(Dimension9Number,'') ne '',1,0)) as Dimension9`,
                `max(casewhen(isnull(Dimension10Number,'') ne '',1,0)) as Dimension10`,
            ].join(',');

            const activeDimensionsRequest = this.statisticsService
                .GetAllUnwrapped(`model=DimensionsInfo&select=${select}`)
                .pipe(
                    catchError(() => of([])),
                    map((res) => {
                        const data = (res && res[0]) || {};
                        return {
                            project: data.Projects > 0,
                            department: data.Departments > 0,
                            dimension5: data.Dimension5 > 0,
                            dimension6: data.Dimension6 > 0,
                            dimension7: data.Dimension7 > 0,
                            dimension8: data.Dimension8 > 0,
                            dimension9: data.Dimension9 > 0,
                            dimension10: data.Dimension10 > 0,
                        };
                    }),
                );

            this.cachedDimensionData = forkJoin([
                activeDimensionsRequest,
                this.customDimensionService.getMetadata().pipe(catchError(() => of([]))),
            ]).pipe(shareReplay(1));
        }

        return this.cachedDimensionData.toPromise();
    }
}
