<div>
    <uni-tabs [tabs]="tabs" [(activeIndex)]="activeTabIndex"></uni-tabs>

    <div class="page scrollable" *ngIf="activeTabIndex == 0">
        <ag-grid-wrapper
            (keydown.esc)="$event.stopPropagation()"
            [(resource)]="main"
            [config]="mainConfig"
        ></ag-grid-wrapper>
    </div>

    <div class="page scrollable" *ngIf="activeTabIndex == 1">
        <ag-grid-wrapper
            (keydown.esc)="$event.stopPropagation()"
            (rowDelete)="onInputDelete($event)"
            [(resource)]="inputs"
            [config]="inputConfig"
        ></ag-grid-wrapper>
    </div>

    <div class="page scrollable" *ngIf="activeTabIndex == 2">
        <ag-grid-wrapper
            (keydown.esc)="$event.stopPropagation()"
            (rowDelete)="onDataDelete($event)"
            [(resource)]="routes"
            [config]="dataConfig"
        ></ag-grid-wrapper>
    </div>

    <div class="page scrollable" *ngIf="activeTabIndex == 3">
        <biz-report-layout-editor [report]="report" (reportChange)="onLayoutChanged($event)"></biz-report-layout-editor>
    </div>

    <div class="page scrollable" *ngIf="activeTabIndex == 4">
        <ag-grid-wrapper
            (keydown.esc)="$event.stopPropagation()"
            (rowDelete)="oni18nDelete($event)"
            [(resource)]="i18n"
            [config]="i18nConfig"
        ></ag-grid-wrapper>
    </div>

    <div class="page scrollable" [hidden]="activeTabIndex != 5">
        <textarea
            style="min-height: 20rem; overflow: auto"
            [value]="json"
            (change)="onJsonChanged($event.target.value)"
        >
        </textarea>
    </div>
</div>
