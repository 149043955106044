<uni-toolbar [config]="toolbarConfig" [saveactions]="saveActions">
    <input-array
        [report]="reportSource"
        [settings]="reportService.settings"
        (inputChange)="onInputChange($event)"
        (viewSettingChange)="onViewSettingChange()"
    >
    </input-array>
</uni-toolbar>

<section class="view-container flex-column">
    <div [attr.aria-busy]="busy"></div>
    <section class="report-wrapper flex-column">
        <section
            #container
            class="table-scroll-wrapper flex-1 custom-scrollbar"
            (scroll)="reportService.updateScrollPosition($event)"
            (click)="onTableClick($event)"
        >
            <!-- Table attaches here -->
        </section>

        <section *ngIf="loadingMore" class="load-more-indicator flex-0-auto">
            <mat-progress-bar class="uni-progress-bar" mode="indeterminate"></mat-progress-bar>
        </section>
    </section>
</section>
