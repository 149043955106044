import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IBizReport, IBizReportSettings } from './IBizReport';
import { ReportData, ReportDataSets } from './ReportData';
import { AuthService } from '@app/authService';
import { FinancialYearService } from '@app/services/accounting/financialYearService';

@Injectable()
export class BizReportService {
    private state: {
        url: string;
        data: ReportDataSets;
        scrollPosition?: number;
    };

    settings: IBizReportSettings = {
        financialYear: this.yearService.getActiveYear(),
        decimalPlaces: 0,
        layoutIndex: 0,
        lang: 'NO',
        showGroupContent: true,
    };

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private yearService: FinancialYearService,
    ) {
        this.authService.companyChange.subscribe(() => (this.state = undefined));
    }

    get data() {
        return this.state?.data;
    }

    clearState() {
        this.state = undefined;
    }

    loadData(report: IBizReport, keepScrollPositionOnReload?: boolean) {
        if (this.state?.url === window.location.href) {
            return of(this.state.data);
        }

        return ReportData.FetchData(report, this.http, this.settings).pipe(
            tap((data) => {
                const scrollPosition = this.state?.scrollPosition || 0;
                this.state = {
                    url: window.location.href,
                    data,
                    scrollPosition: keepScrollPositionOnReload ? scrollPosition : 0,
                };
            }),
        );
    }

    loadNextPage(report: IBizReport) {
        this.settings.page++;

        const layout = report.Layouts && report.Layouts[this.settings.layoutIndex];
        if (!layout?.DatasetSupportsPaging) {
            return of(new ReportDataSets([]));
        }

        return ReportData.FetchData(report, this.http, this.settings).pipe(tap((data) => this.data.addPage(data)));
    }

    updateScrollPosition(scrollEvent: Event) {
        const element = scrollEvent.target as HTMLElement;
        if (element.scrollTop && this.state) {
            this.state.scrollPosition = element.scrollTop;
        }
    }

    clearScrollPosition() {
        if (this.state) {
            this.state.scrollPosition = 0;
        }
    }

    restoreScrollPosition(element: HTMLElement) {
        if (element && this.state?.scrollPosition) {
            element.scrollTop = this.state.scrollPosition;
        }
    }
}
