import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Subscription } from 'rxjs';
import { IBizReport, IBizReportInput, IBizReportLayout, IBizReportRenderer } from './IBizReport';
import { ReportData, ReportDataSets } from './ReportData';
import { ReportRender } from './ReportRenderer';
import { IToolbarConfig } from '@app/components/common/toolbar/toolbar';
import { ConfirmActions, UniModalService, UniPreviewModal } from '@uni-framework/uni-modal';
import { UniReportParamsModal } from '../modals/parameter/reportParamModal';
import { IUniSaveAction } from '@uni-framework/save/save';
import { exportToFile, filterInput, trimLength } from '@app/components/common/utils/utils';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { loadExcelJS } from '@app/components/common/utils/excel/excel';
import { CsvReportRenderer } from './csvreportrenderer';
import { CompanySettings } from '@uni-entities';
import { IInputChange, InputArrayComponent } from './inputarray/inputarraycomponent';
import { IReportEditConfig, BizReportEditorModal } from './editor/EditorModal';
import { BizReportService } from './biz-report-service';
import { DrilldownService } from './drilldown/drilldown-service';
import { TabService, UniModules } from '@app/components/layout/navbar/tabstrip/tabService';
import { CompanySettingsService } from '@app/services/common/companySettingsService';
import { PageStateService } from '@app/services/common/pageStateService';
import { debounceTime } from 'rxjs/operators';
import { ExcelReportRenderer } from './excelreportrenderer';
import { FinancialYearService } from '@app/services/accounting/financialYearService';
import { rigDate } from '@app/components/common/utils/rig-date';

@Component({
    selector: 'biz-report',
    templateUrl: './bizreportcomponent.html',
    styleUrls: ['./BizReportComponent.sass'],
})
export class BizReportComponent {
    @ViewChild(InputArrayComponent) inputArray: InputArrayComponent;
    @ViewChild('container', { static: true }) container: ElementRef<HTMLElement>;
    excelScriptTag: HTMLScriptElement;

    toolbarConfig: IToolbarConfig;
    reportListUrl: string;

    saveActions: IUniSaveAction[] = [
        { label: 'Eksport', action: (done) => this.exportExcel(done) },
        { label: 'Forhåndsvis', action: (done) => this.exportToStimulsoft(done) },
        { label: 'Epost', action: (done) => this.exportToEmail(done) },
    ];

    reportSource: IBizReport;
    currentReportId: number;
    currentReportJson: string;
    busy = false;
    loadingMore = false;
    layouts: Array<IBizReportLayout> = [];
    dateSelector: { use: boolean; fromDate?: Date; toDate?: Date; inputs: IBizReportInput[] } = {
        use: false,
        inputs: [],
    };

    layoutSelectConfig = {
        template: (item) => item.Label,
        searchable: false,
        hideDeleteButton: true,
    };

    scrollObserver: IntersectionObserver;

    private meta = { title: '', subTitle: '', reportName: '' };
    private companySettings: CompanySettings;
    private routeSubscription: Subscription;
    private sortable: boolean;

    constructor(
        private http: HttpClient,
        private ngRender: Renderer2,
        private pageStateService: PageStateService,
        private uniModalService: UniModalService,
        private route: ActivatedRoute,
        private router: Router,
        private financialYearService: FinancialYearService,
        private companySettingsService: CompanySettingsService,
        public reportService: BizReportService,
        private drilldownService: DrilldownService,
        private tabService: TabService,
    ) {
        this.reportService.clearState(); //Fresh data when report is opened
        combineLatest([this.route.params, this.route.queryParams])
            .pipe(debounceTime(1))
            .subscribe(([params, queryParams]) => {
                this.loadState(params, queryParams);
            });

        this.companySettingsService.getCompanySettings().subscribe((settings) => {
            this.companySettings = settings;
        });

        const reportListTab = this.tabService.tabs.find((tab) => tab.url.includes('/reports?category='));
        this.reportListUrl = reportListTab?.url || '/reports';
    }

    ngOnDestroy() {
        this.scrollObserver?.disconnect();
        this.drilldownService.disconnect();
        this.routeSubscription?.unsubscribe();
    }

    loadState(params: Params, queryParams: Params) {
        if (params.id) {
            this.loadReportById(params.id, this.pageStateService.getPageState());
        }

        const [sortField, sortDirection] = decodeURI(queryParams.sort || '').split(' ');
        if (sortField && sortDirection) {
            this.reportService.settings.sortField = sortField;
            this.reportService.settings.sortDirection = sortDirection;
        }
    }

    editReport(): any {
        this.uniModalService
            .open(BizReportEditorModal, {
                data: <IReportEditConfig>{
                    reportId: this.currentReportId,
                    json: this.currentReportJson,
                    updated: false,
                },
            })
            .onClose.subscribe((modalResult: IReportEditConfig) => {
                if (modalResult?.updated) {
                    this.clear(true);
                    this.currentReportJson = modalResult.json;
                    this.loadReportFromJson(modalResult.json, this.pageStateService.getPageState());
                }
            });
    }

    loadReportById(id: string, routeParams: any) {
        let currYear = this.financialYearService.getActiveYear();
        if (!routeParams.yr || routeParams.yr == 'yyyy') {
            routeParams.yr = currYear;
        }
        this.busy = true;
        this.clear();
        const filter = parseInt(id) > 0 ? 'id eq ' + id : `reportname eq '` + id + `'`;
        this.http
            .get(
                '/api/statistics?model=reportdefinition&select=id as ID,reportsource as ReportSource&wrap=false&filter=' +
                    filter,
            )
            .subscribe((x) => {
                if (x && x[0]) {
                    const row = x[0];
                    this.currentReportId = row['ID'];
                    this.currentReportJson = row['ReportSource'];
                    this.loadReportFromJson(this.currentReportJson, routeParams);
                } else {
                    this.busy = false;
                }
            });
    }

    private loadMore(currentLastRow) {
        this.loadingMore = true;

        this.reportService
            .loadNextPage(this.reportSource)
            .subscribe(() => {
                this.renderReport(this.reportSource, this.reportService.data, currentLastRow);
            })
            .add(() => (this.loadingMore = false));
    }

    private updateToolbarAndTab() {
        this.toolbarConfig = {
            title: this.meta.title,
            buttons: [
                {
                    label: 'Rapportoversikt',
                    action: () => this.router.navigateByUrl(this.reportListUrl),
                },
            ],
        };

        this.tabService.addTab({
            name: this.meta.title,
            url: this.pageStateService.getUrl(),
            moduleID: UniModules.Reports,
            active: true,
        });

        if (this.pageStateService.getPageState().edit) {
            this.toolbarConfig.buttons.push({
                label: 'Rediger',
                action: () => this.editReport(),
            });
        }
    }

    loadReportFromJson(json: string, routeParams: any) {
        const rep = <IBizReport>JSON.parse(json);
        if (rep) {
            this.selectLayoutFromRoute(rep, routeParams);
            this.meta.reportName = rep.Name;
            this.meta.subTitle = this.inputArray.subTitle;
            this.reportSource = rep;
            if (!routeParams.length) {
                this.inputArray.analyzeInputs(rep, routeParams, this.reportService.settings);
                const report = this.convertToReportDefinition(rep);
                (<{ Name: string; value: any }[]>report['parameters']).forEach((par) => {
                    if (par.value) {
                        routeParams[par.Name] = '' + par.value;
                        this.pageStateService.setPageState(par.Name, '' + par.value);
                    }
                });
            }
            this.showReport(rep, routeParams);
        }
    }

    clear(clearSelected = true) {
        this.reportService.settings.page = 1;
        const current = this.container?.nativeElement.getElementsByClassName('reportcontainer');
        if (current?.length) {
            this.ngRender.removeChild(this.container?.nativeElement, current[0]);
        }
    }

    showReport(rep: IBizReport, routeParams: any, analyzeInputs = true, restoreScrollPosition = false) {
        this.inputArray.analyzeInputs(rep, routeParams, this.reportService.settings);
        this.meta.title = ReportData.interpolate(this.translate(rep, rep.Title || rep.Name), routeParams);
        this.updateToolbarAndTab();

        const layout = this.getCurrentLayout();
        this.sortable = !layout?.DatasetSupportsPaging;

        this.scrollObserver?.disconnect();

        if (analyzeInputs) {
            this.inputArray.analyzeInputs(rep, routeParams, this.reportService.settings);
        }

        this.reportService.loadData(rep, restoreScrollPosition).subscribe(() => {
            this.renderReport(rep, this.reportService.data);
            this.busy = false;
        });
    }

    reRender(restoreScrollPosition = false) {
        if (!restoreScrollPosition) {
            this.reportService.clearScrollPosition();
        }

        this.clear(false);
        this.renderReport(this.reportSource, this.reportService.data);
    }

    renderReport(src: IBizReport, data: ReportDataSets, lastRow?: HTMLElement) {
        const showGroupContent = this.reportService.settings?.showGroupContent ?? true;
        const rx = new ReportRender(src, data, this.reportService.settings);
        const renderResult = rx.createElements(
            this.ngRender,
            this.reportService.settings.layoutIndex,
            !showGroupContent,
            this.sortable,
        );
        let container: HTMLElement = renderResult.container;
        const table = <any>container.getElementsByTagName('table')[0];

        if (lastRow) {
            // render next page
            const tbody = lastRow.parentElement;
            container = tbody.parentElement.parentElement;
            const startIndex = tbody.childNodes.length + 1;
            while (table.rows.length > startIndex) {
                lastRow = table.rows[startIndex];
                this.ngRender.appendChild(tbody, lastRow);
            }
        } else {
            lastRow = table.rows[table.rows.length - 1];
            this.ngRender.addClass(container, 'reportcontainer');
            this.ngRender.appendChild(this.container.nativeElement, container);

            const endOfScroll = this.ngRender.createElement('div');
            this.ngRender.setAttribute(endOfScroll, 'id', 'end-of-scroll');
            this.ngRender.appendChild(container, endOfScroll);

            this.drilldownService.connect(this.container.nativeElement, this.reportSource, this.getCurrentLayout());
        }

        this.reportService.restoreScrollPosition(this.container?.nativeElement);

        this.scrollObserver?.disconnect();
        if (renderResult.hasMoreData) {
            this.setupScrollObserver(lastRow);
        }
    }

    private setupScrollObserver(currentLastRow) {
        this.scrollObserver = new IntersectionObserver(([observedElement]) => {
            if (observedElement.isIntersecting && !this.loadingMore) {
                this.loadMore(currentLastRow);
            }
        });

        const endOfScroll = document.getElementById('end-of-scroll');
        if (endOfScroll) {
            this.scrollObserver.observe(endOfScroll);
        }
    }

    private getCurrentLayout() {
        const layoutIndex = this.reportService.settings.layoutIndex;
        return this.reportSource.Layouts[layoutIndex];
    }

    selectLayoutFromRoute(rep: IBizReport, routeParams: any) {
        let index = 0;
        if (rep.Layouts?.length && routeParams.layout) {
            index = rep.Layouts.findIndex((x) => x.Name?.toLowerCase() === routeParams.layout.toLowerCase());
        }

        this.reportService.settings.layoutIndex = index >= 0 ? index : 0;
        this.reportService.settings.decimalPlaces =
            rep.Layouts[index]?.Decimals ?? this.reportService.settings.decimalPlaces;
    }

    onInputChange(event: IInputChange) {
        if (event.action === 'edit-options') {
            this.onFilterClick(event.inputs[0]);
            return;
        }

        // Layoutchange
        if (event.layout) {
            this.selectLayout(event.layout);
            return;
        }

        // Inputchange
        let routeParams = {};
        event.inputs.forEach((x) => {
            routeParams[x.Name] = '' + x.Default;
            this.pageStateService.setPageState(x.Name, '' + x.Default);
        });

        // TODO: add support for restoring scroll when multiple pages are loaded
        const canRestoreScrollPosition =
            this.reportService.settings.page <= 1 && event.inputs.length === 1 && event.inputs[0].Name === 'flipsign';

        this.clear(true);
        this.showReport(this.reportSource, routeParams, false, canRestoreScrollPosition);
    }

    onViewSettingChange() {
        this.reRender(true);
    }

    onTableClick(event: MouseEvent) {
        const target = event.target as HTMLElement;

        const tableHeader = target.tagName === 'TH' ? target : target.closest('TH');
        const sortField = tableHeader?.getAttribute('data-column-name');
        if (this.sortable && sortField) {
            const settings = this.reportService.settings;

            if (settings.sortField === sortField) {
                if (settings.sortDirection === 'asc') {
                    settings.sortDirection = undefined;
                    settings.sortField = undefined;
                } else {
                    settings.sortDirection = settings.sortDirection === 'desc' ? 'asc' : 'desc';
                    settings.sortField = sortField;
                }
            } else {
                settings.sortDirection = 'desc';
                settings.sortField = sortField;
            }

            if (settings.sortField && settings.sortDirection) {
                this.pageStateService.setPageState('sort', `${settings.sortField} ${settings.sortDirection}`);
            } else {
                this.pageStateService.deletePageState('sort');
            }

            this.reportService.settings = settings;
            this.reRender(false);
        }
    }

    public selectLayout(layout: IBizReportLayout) {
        const index = this.reportSource.Layouts?.indexOf(layout);
        this.reportService.settings.layoutIndex = index >= 0 ? index : 0;
        this.pageStateService.setPageState('layout', layout.Name);
        this.reRender();
    }

    public onFilterClick(par: IBizReportInput) {
        const report = this.convertToReportDefinition(this.reportSource);
        this.uniModalService
            .open(UniReportParamsModal, {
                data: report,
                header: report.Name,
                message: report.Description,
            })
            .onClose.subscribe((modalResult) => {
                if (modalResult === ConfirmActions.ACCEPT) {
                    let routeParams = {};
                    (<{ Name: string; value: any }[]>report['parameters']).forEach((par) => {
                        if (par.value) {
                            routeParams[par.Name] = '' + par.value;
                            this.pageStateService.setPageState(par.Name, '' + par.value);
                        }
                    });
                    this.clear(true);
                    this.showReport(this.reportSource, routeParams);
                }
            });
    }

    private convertToReportDefinition(rep: IBizReport): {
        ID: any;
        Name: string;
        Description: string;
        parameters?: Array<any>;
        action?: string;
        layout?: string;
    } {
        const parameters = rep.Input.map((x) => {
            return { Name: x.Name, value: x.Default, presetValue: x.Default };
        });
        const label = ReportData.interpolate(this.translate(rep, rep.Title || rep.Name), parameters);
        return {
            ID: this.currentReportId,
            Name: label,
            Description: this.translate(rep, rep['Description']) || label,
            parameters: parameters,
        };
    }

    translate(rep: IBizReport, value: string, lng = 'NO') {
        return ReportRender.translate(rep, value, lng);
    }

    exportToStimulsoft(done: () => {}) {
        const report = this.convertToReportDefinition(this.reportSource);
        if (this.reportService.settings.layoutIndex > 0) {
            report.layout = this.getCurrentLayout().Name;
        }
        this.uniModalService.open(UniPreviewModal, { data: report });
        done();
    }

    exportToEmail(done: () => {}) {
        const report = this.convertToReportDefinition(this.reportSource);
        report.parameters.push({ Name: 'layout', value: this.getCurrentLayout().Name });
        report.action = 'send';
        this.uniModalService.open(UniReportParamsModal, { data: report });
        done();
    }

    async exportExcel(done: () => {}) {
        const excelJS = await loadExcelJS();
        const layout = this.getCurrentLayout();

        const renderer = excelJS
            ? new ExcelReportRenderer(excelJS, layout, this.reportService.settings)
            : new CsvReportRenderer();

        await this.renderToFile(this.reportSource, renderer);
        done();
    }

    renderToFile(rep: IBizReport, renderer: IBizReportRenderer): Promise<boolean> {
        return new Promise((resolve) => {
            const settings = {
                ...this.reportService.settings,
                page: 0,
                pageSize: -1,
            };
            const parameterInfo = this.inputArray.subTitle;
            const friendlyName =
                settings.lang == 'NO' ? this.reportSource.Alias || this.meta.reportName : this.meta.reportName;

            ReportData.FetchData(rep, this.http, settings)
                .subscribe(async (freshData) => {
                    const rx = new ReportRender(rep, freshData, settings);
                    rx.numberFormat = <any>{
                        format(value) {
                            return renderer.formatNumber(value);
                        },
                    };
                    rx.createElements(<any>renderer, settings.layoutIndex);
                    const fileName =
                        friendlyName +
                        ' ' +
                        filterInput(`${trimLength(this.companySettings.CompanyName, 15)}`) +
                        renderer.getFileExtension();
                    const binData = await renderer.getBuffer(
                        this.meta.title,
                        parameterInfo,
                        this.companySettings?.CompanyName,
                    );
                    const txtData = !!binData
                        ? undefined
                        : renderer.getCsvData(this.meta.title, parameterInfo, this.companySettings?.CompanyName);
                    exportToFile(txtData, fileName, false, binData);
                })
                .add(() => resolve(true));
        });
    }
}
