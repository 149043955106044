import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UniTableColumn, UniTableConfig } from '@uni-framework/ui/unitable';
import { IUniTab } from '@uni-framework/uni-tabs';
import { IBizReport, IBizReportInput, IBizReportLayout, IBizReportSettings } from '../IBizReport';

export interface IBizReportChange {
    report: IBizReport;
    json: string;
}

@Component({
    selector: 'biz-report-editor',
    templateUrl: './BizReportEditorComponent.html',
    styleUrls: ['./BizReportEditorComponent.sass'],
})
export class BizReportEditorComponent {
    @Input() json: string;
    @Input() settings: IBizReportSettings;
    @Output() reportChange = new EventEmitter<IBizReportChange>();

    report: IBizReport;
    tabs: IUniTab[] = [
        { name: 'General' },
        { name: 'Input' },
        { name: 'Data' },
        { name: 'Layout' },
        { name: 'i18n' },
        { name: 'Json' },
    ];
    activeTabIndex = 0;
    routes: Array<{ name: string; route: string }> = [];
    main: Array<{ name: string; value: string }> = [];
    inputs: Array<IBizReportInput> = [];
    i18n: Array<{ Key: string; NO: string; EN: string }> = [];
    mainConfig: UniTableConfig;
    inputConfig: UniTableConfig;
    dataConfig: UniTableConfig;
    i18nConfig: UniTableConfig;

    constructor() {
        this.mainConfig = this.createMainConfig();
        this.inputConfig = this.createInputConfig();
        this.dataConfig = this.createDataConfig();
        this.i18nConfig = this.createI18nConfig();
    }

    ngOnInit() {
        this.parseJsonReport(this.json);
    }

    parseJsonReport(json: string) {
        this.report = <IBizReport>JSON.parse(json);
        this.routes = this.report.Data.routes.map((src) => {
            const name = Object.getOwnPropertyNames(src)[0];
            return { name: name, route: src[name] };
        });
        const props = ['Name', 'Alias', 'Title', 'Description', 'Category', 'UniqueReportID'];
        this.main = props.map((p) => {
            return { name: p, value: this.report[p] };
        });
        this.inputs = this.report.Input.map((x) => {
            return { ...x };
        });
        this.i18n = this.loadLanguages(this.report);
    }

    loadLanguages(report: IBizReport): Array<{ Key: string; NO: string; EN: string }> {
        const langArray = [];
        if (report.I18n === undefined) return langArray;
        const langNO = report.I18n['NO'];
        const langEN = report.I18n['EN'];
        if (langNO) {
            Object.getOwnPropertyNames(langNO).forEach((name) => {
                langArray.push({ Key: name, NO: langNO[name], EN: langEN ? langEN[name] : '' });
            });
        }
        return langArray;
    }

    onJsonChanged(value: string) {
        this.json = value;
        this.reportChange.emit({ json: this.json, report: this.report });
    }

    onReportChanged(report: IBizReport) {
        this.json = JSON.stringify(report, null, 2);
        this.reportChange.emit({ json: this.json, report: this.report });
    }

    // General / main

    private createMainConfig(): UniTableConfig {
        return new UniTableConfig('reporteditor.maineditor', true)
            .setDeleteButton(false)
            .setAutoAddNewRow(false)
            .setColumnMenuVisible(false)
            .setColumns([
                new UniTableColumn('name', '').setWidth('2em').setEditable(false),
                new UniTableColumn('value', 'Value'),
            ])
            .setChangeCallback((event) => {
                const item = event.rowModel;
                this.report[item.name] = item.value;
                this.onReportChanged(this.report);
                return item;
            });
    }

    // Inputs (parameters)

    private createInputConfig(): UniTableConfig {
        return new UniTableConfig('reporteditor.inputeditor', true)
            .setDeleteButton(true)
            .setColumns([
                new UniTableColumn('Name', 'Name'),
                new UniTableColumn('Label', 'Label'),
                new UniTableColumn('Default', 'Default'),
                new UniTableColumn('Type', 'Type'),
                new UniTableColumn('SearchModel', 'SearchModel'),
            ])
            .setChangeCallback((event: { rowModel: any; field: string; newValue: string; originalIndex: number }) => {
                const item = event.rowModel;
                if (event.originalIndex < this.report.Input.length) {
                    const match = this.report.Input[event.originalIndex];
                    match[event.field] = event.newValue;
                    this.onReportChanged(this.report);
                } else {
                    this.report.Input.push(this.mapInto(item, ['Name', 'Label', 'Default', 'Type', 'SearchModel']));
                }
                return item;
            });
    }

    onInputDelete(event: { _originalIndex: number }) {
        if (event._originalIndex >= 0) {
            this.report.Input.splice(event._originalIndex, 1);
            this.onReportChanged(this.report);
        }
    }

    // Data (routes)

    private createDataConfig(): UniTableConfig {
        return new UniTableConfig('reporteditor.dataeditor', true)
            .setDeleteButton(true)
            .setColumns([new UniTableColumn('name', 'Name').setWidth('2em'), new UniTableColumn('route', 'Route')])
            .setChangeCallback((event: { rowModel: any; field: string; newValue: string; originalIndex: number }) => {
                const item = event.rowModel;
                const arr = this.report.Data.routes;
                if (event.originalIndex < arr.length) {
                    const route = arr[event.originalIndex];
                    const routeName = Object.getOwnPropertyNames(route)[0];
                    if (event.field == 'name') {
                        route[event.newValue] = route[routeName];
                        delete route[routeName];
                    } else {
                        route[routeName] = event.newValue;
                    }
                } else {
                    arr.push(this.newRoute(item.name || 'new', item.route || ''));
                }
                this.onReportChanged(this.report);
                return item;
            });
    }

    private newRoute(name: string, route: string): any {
        const result = {};
        result[name] = route;
        return result;
    }

    onDataDelete(event: { _originalIndex: number }) {
        if (event._originalIndex >= 0) {
            this.report.Data.routes.splice(event._originalIndex);
            this.onReportChanged(this.report);
        }
    }

    // Lyoyut

    // i18n

    private createI18nConfig(): UniTableConfig {
        return new UniTableConfig('reporteditor.i18n', true)
            .setDeleteButton(true)
            .setColumns([
                new UniTableColumn('Key', 'Key').setWidth('5em'),
                new UniTableColumn('NO', 'NO'),
                new UniTableColumn('EN', 'EN'),
            ])
            .setChangeCallback((event: { rowModel: any; field: string; newValue: string; originalIndex: number }) => {
                const item = event.rowModel;
                const arr = this.i18n;
                const langNO = this.report.I18n['NO'];
                const langEN = this.report.I18n['EN'];
                const keys = Object.getOwnPropertyNames(langNO);
                if (event.originalIndex < keys.length) {
                    const row = arr[event.originalIndex];
                    const oldKey = row.Key;
                    if (event.field == 'Key') {
                        const newKey = event.newValue;
                        const v1 = langNO[oldKey];
                        const v2 = langEN[oldKey];
                        delete langNO[oldKey];
                        delete langEN[oldKey];
                        langNO[newKey] = v1;
                        langEN[newKey] = v2;
                    } else {
                        this.report.I18n[event.field][oldKey] = event.newValue;
                    }
                } else {
                    if (event.field === 'Key') {
                        langNO[event.newValue] = '?';
                        langEN[event.newValue] = '?';
                        item.NO = '?';
                        item.EN = '?';
                    } else {
                        const key = '??';
                        langNO[key] = event.newValue;
                        langEN[key] = event.newValue;
                        item.Key = key;
                        item.NO = event.newValue;
                        item.EN = event.newValue;
                    }
                }
                this.onReportChanged(this.report);
                return item;
            });
    }

    onLayoutChanged(report: IBizReport) {
        this.onReportChanged(report);
    }

    private mapInto(item: any, props: string[]): any {
        const result = {};
        props.forEach((prop) => {
            result[prop] = item[prop];
        });
        return result;
    }
}
